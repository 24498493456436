import React from 'react'
import { graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image/withIEPolyfill'
import styled from 'styled-components'
import ImageGallery from 'react-image-gallery'

import { useIsMobile } from '../hooks'

export function Gallery({ className, data, location }) {
  const {
    allFile: { edges: photos },
  } = data

  const images = photos
    .sort((a, b) => ('' + a.node.name).localeCompare(b.node.name))
    .map(({ node: { id, name, childImageSharp: { fluid } } }) => ({
      original: fluid.src,
      thumbnail: fluid.src,
      id,
      name,
      fluid,
    }))

  const { id } = location.state || images.find(i => i)
  const startIndex = images.findIndex(img => img.id === id)
  const isMobile = useIsMobile()

  const renderItem = React.useCallback(
    item => <GatsbyImage fluid={item.fluid} objectFit='contain' />,
    [],
  )

  return (
    <div className={`${className} h-full`}>
      <ImageGallery
        items={images}
        startIndex={startIndex}
        useTranslate3D={false}
        showThumbnails={!isMobile}
        showFullscreenButton={!isMobile}
        showPlayButton={!isMobile}
        renderItem={renderItem}
      />
    </div>
  )
}

export const query = graphql`
  query($folder: String!) {
    allFile(filter: { relativeDirectory: { eq: $folder } }) {
      edges {
        node {
          name
          relativeDirectory
          childImageSharp {
            fluid(maxWidth: 1280, quality: 90) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }
      }
    }
  }
`

export default styled(Gallery)`
  margin-top: auto;
  margin-bottom: auto;

  .image-gallery,
  .image-gallery-content {
    height: calc(100vh - 4rem);
  }
  .image-gallery-slide-wrapper,
  .image-gallery-swipe,
  .image-gallery-slides,
  .image-gallery-slide,
  .image-gallery-slide > div {
    height: calc(100vh - 10rem);
  }

  .image-gallery-thumbnails-wrapper {
    height: 6rem;
  }

  .image-gallery-thumbnails {
    padding: 0.7rem 0;
  }

  .image-gallery-slide {
    outline: none;
    cursor: auto;
  }

  .image-gallery-slide > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .image-gallery-slide > div > img.image-gallery-image {
    height: auto;
    max-width: 1280px;
  }

  .image-gallery-slide > div > img {
    width: 100%;
    object-fit: contain;
    object-position: center center;
    overflow: hidden;
    margin-bottom: 0;
  }

  @media only screen and (max-width: ${props => props.theme.breakpoints.values.md}px) {
    height: calc(100vh - 4rem);
    .image-gallery,
    .image-gallery-content,
    .image-gallery-slide-wrapper,
    .image-gallery-swipe,
    .image-gallery-slides,
    .image-gallery-slide,
    .image-gallery-slide > div {
      height: calc(100vh - 4rem);
    }
  }

  .fullscreen .image-gallery-slide-wrapper,
  .fullscreen .image-gallery-swipe,
  .fullscreen .image-gallery-slides,
  .fullscreen .image-gallery-slide,
  .fullscreen .image-gallery-slide > div {
    height: calc(100vh - 6rem);
  }

  .fullscreen .image-gallery-slide img {
    height: auto;
    max-height: 85vh;
  }

  .image-gallery-thumbnail.active,
  .image-gallery-thumbnail:hover {
    border-color: ${props => props.theme.palette.primary.main};
  }

  .image-gallery-icon {
    background-color: rgba(255, 255, 255, 0.55);
    color: ${props => props.theme.palette.primary.main};
    filter: none;
    outline: none;
    margin: 0 1rem;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  .image-gallery-icon > .image-gallery-svg {
    transition: transform 200ms linear;
    width: 3rem;
    height: 3rem;
  }

  .fullscreen .image-gallery-icon {
    background-color: rgba(0, 0, 0, 0.75);
  }

  .image-gallery-icon:hover {
    color: ${props => props.theme.palette.primary.dark};
  }

  .image-gallery-icon:focus {
    outline: 2px solid ${props => props.theme.palette.primary.main};
  }

  .image-gallery-left-nav {
    margin: 0;
    left: 1rem;
  }

  .image-gallery-right-nav {
    margin: 0;
    right: 1rem;
  }

  .image-gallery-thumbnail-inner img {
    width: 100%;
    height: 75px;
    margin-bottom: 0;
    object-fit: cover;
    object-position: 15% 50%;
  }
`
