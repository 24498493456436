import React from 'react'

import { ScreenSizeContext } from '../providers'

export default function useScreenSize() {
  const context = React.useContext(ScreenSizeContext)

  if (!context) {
    throw new Error(`useScreenSize must be used within a ScreenSizeContext`)
  }

  return context
}
